
export const ExibitionContent1 = [
    {
        image: require('./../images/duynhat/products/8_bien_2.jpg'),
    },
    {
        image: require('./../images/duynhat/products/DSC_9304.jpg'),
    },
    {
        image: require('./../images/duynhat/products/Paper/DSC_9343.jpg'),
    },
    {
        image: require('./../images/duynhat/products/Paper/DSC_9450.jpg'),
    },
]



export const homeSliderContent4 = [
    {
        image: require("../images/duynhat/factory/DSC_9257.jpg"),
        title:"Sản phẩm bao bì nhựa, bao bì giấy, tem và nhãn",
        description:"Với nhiều năm kinh nghiệm, Bao Bì Duy Nhật chuyên cung cấp các loại bao bì nhựa và bao bì giấy cho nông – lâm nghiệp, thủy – hải sản, phục vụ sản xuất kinh doanh hàng hóa ở nhiều ngành, nhiều lĩnh vực."
    },
    {
        image: require("../images/duynhat/factory/DSC_7895.jpg"),
        title:"Trang thiết bị hiện đại và công nghệ sản xuất tối tân",
        description:"Nhà máy của chúng tôi được trang bị những trang thiết bị hiện đại và tối tân nhất từ cắt khuôn, rập nổi, đến gấp túi, dán túi để phục vụ các nhu cầu sản phẩm khác nhau của khách hàng."
    },
    {
        image: require("../images/duynhat/factory/DSC_3325.jpg"),
        title:"Hệ thống quản lý và đảm bảo chất lượng",
        description:"Bên cạnh hệ thống quản lý chất lượng sản phẩm chặt chẽ, Bao Bì Duy Nhật không ngừng nâng cao trình độ công nghệ để đáp ứng các chuẩn mực chất lượng quốc tế. Công ty đang áp dụng hệ thống quản lý chất lượng ISO 9001:2005 và BRC cho thực phẩm nhầm giảm thiểu lỗi và rủi ro cho sản phẩm khi đến tay khách hàng."
    }
]
export const homeSliderContent5 = [
    {
        image: require("../images/duynhat/factory/DSC_9257.jpg"),
        title:"Sản phẩm bao bì nhựa, bao bì giấy, tem và nhãn",
        description:"Với nhiều năm kinh nghiệm, Bao Bì Duy Nhật chuyên cung cấp các loại bao bì nhựa và bao bì giấy cho nông – lâm nghiệp, thủy – hải sản, phục vụ sản xuất kinh doanh hàng hóa ở nhiều ngành, nhiều lĩnh vực."
    },
    {
        image: require("../images/duynhat/factory/DSC_7895.jpg"),
        title:"Trang thiết bị hiện đại và công nghệ sản xuất tối tân",
        description:"Nhà máy của chúng tôi được trang bị những trang thiết bị hiện đại và tối tân nhất từ cắt khuôn, rập nổi, đến gấp túi, dán túi để phục vụ các nhu cầu sản phẩm khác nhau của khách hàng."
    },
    {
        image: require("../images/duynhat/factory/DSC_3325.jpg"),
        title:"Hệ thống quản lý và đảm bảo chất lượng",
        description:"Bên cạnh hệ thống quản lý chất lượng sản phẩm chặt chẽ, Bao Bì Duy Nhật không ngừng nâng cao trình độ công nghệ để đáp ứng các chuẩn mực chất lượng quốc tế. Công ty đang áp dụng hệ thống quản lý chất lượng ISO 9001:2005 và BRC cho thực phẩm nhầm giảm thiểu lỗi và rủi ro cho sản phẩm khi đến tay khách hàng."
    }
]

export const homeSliderContentFactory = [
    {
        image: require("../images/duynhat/factory/DSC_3325.jpg"),
        title:"",
    },
    {
        image: require("../images/duynhat/factory/DSC_7874.jpg"),
        title:"",
    },
    {
        image: require("../images/duynhat/factory/DSC_9249.jpg"),
        title:"",
    },
    {
        image: require("../images/duynhat/factory/DSC_9222.jpg"),
        title:"",
    },
    {
        image: require("../images/duynhat/factory/DSC_9234.jpg"),
        title:"",
    },
    {
        image: require("../images/duynhat/factory/DSC_9237.jpg"),
        title:"",
    },
    
]
